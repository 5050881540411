<script lang="ts">
import {storage} from "@/features/quakewatch/utils/storage";

export default {
  name: "CookieModal",
  data: () => ({
    isModalOpen: !storage.isStorageAllowed(),
  }),

  methods: {
    acceptCookies(){
      this.isModalOpen = false;
      storage.allowStorage();
    },
    rejectCookies(){
      this.isModalOpen = false;
    }
  }
}
</script>

<template>
  <b-modal
      v-model="isModalOpen"
      body-class="cookie-modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      hide-header
      aria-label="Cookie Modal"
      title="Cookie Modal"
  >
    <div id="cookie-text">
      Cookies are used on this site to remember your settings. Cookies are small text files stored in your browser or computer.
    </div>

    <div id="cookie-buttons">
      <b-link id="cookie-close" @click="rejectCookies()">
        <b-icon icon="x-square" title="Reject Cookies" />
      </b-link>
      <b-button class="mt-2 cookie-button" block @click="acceptCookies()">
        Accept
      </b-button>
    </div>
  </b-modal>
</template>

<style>
  .cookie-modal.modal-body {
    height: 170px;
  }

  .cookie-button {
    /*background-color: #abdde5;*/
    position: relative;
    top:70px;
  }

  #cookie-buttons {
    float: right;
    position: relative;
    width: 7%;
    min-width: 100px;
    display: inline-block;
  }

  #cookie-text {
    margin: auto;
    position: absolute;
    width: 60%;
    text-align: center;
  }

  #cookie-close {
    color: rgba(0,0,0,.5);
    font-size: 125%;
    float: right;
    position: relative;
    bottom:10px;
  }
</style>
