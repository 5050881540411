$cookies.config('1000d')

export const storage = {
  set(key, value){
    if(storage.isStorageAllowed()){
      $cookies.set(key, JSON.stringify(value));
    }
  },

  get(key, defaultValue){
    try{
      if(!$cookies.isKey(key)){
        return defaultValue
      }

      return JSON.parse($cookies.get(key));
    }catch (e){
      return defaultValue;
    }
  },

  has(key){
    return $cookies.isKey(key);
  },

  remove(key){
    return $cookies.remove(key)
  },

  isStorageAllowed(){
    return storage.get("allowCookies");
  },

  allowStorage(){
    return $cookies.set("allowCookies", JSON.stringify(true));
  }
}
